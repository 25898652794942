import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

import CheckboxCard from "./CheckboxCard"

const ContactForm = ({ type }) => {
  const questionaire = [
    {
      name: "New Shopify Store Setup",
      value: "newstore",
    },
    {
      name: "Store Optimization",
      value: "theme",
    },
    {
      name: "Migration to Shopify",
      value: "migration",
    },
    {
      name: "Digital Marketing",
      value: "marketing",
    },
  ]

  switch (type) {
    case "underlined":
      return (
        <form
          className="flex flex-col items-start w-full"
          name="Contact Form"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <div className="w-full flex flex-col items-center sm:flex-row">
            <div className="w-full mb-8 sm:pr-2">
              <label className="block mb-2 hidden " htmlFor="contact-name">
                Name
              </label>
              <input
                id="contact-name"
                type="text"
                className="pb-3 text-lg border-b border-neutral-300 w-full placeholder:text-neutral-700 outline-none focus:border-neutral-900"
                placeholder="Your Name * "
                required
              />
            </div>
            <div className="w-full mb-8 sm:pl-2">
              <label className="block mb-2 hidden" htmlFor="contact-email">
                Email
              </label>
              <input
                id="contact-email"
                type="email"
                name="_replyto"
                className="pb-3 text-lg border-b border-neutral-300 w-full placeholder:text-neutral-700 outline-none focus:border-neutral-900"
                placeholder="Your Email * "
                required
              />
            </div>
          </div>
          {/*<div className="w-full mb-8">
                       <h4 className="mb-2">What services are you looking for?</h4>
                        <div className="flex flex-wrap w-full justify-between">
                            {
                                questionaire.map( item  => (
                                    <div className="pb-2">
                                       <CheckboxCard name={item.name} value={item.value}/>
                                    </div>
                                )
                            )
                            }   
                        </div> 
                    </div>*/}
          <div className="w-full mb-16 relative">
            <label className="block mb-4 hidden" htmlFor="contact-message">
              Project Details
            </label>
            <textarea
              id="contact-message"
              name="message"
              className="pb-3 text-lg border-b border-neutral-300 w-full placeholder:text-neutral-700 outline-none focus:border-neutral-900"
              rows="5"
              placeholder="Your Project Details * "
              row="1"
            ></textarea>
          </div>

          <button
            type="submit"
            className=" bg-gradient-to-r from-cyan-400 to-cyan-500 group text-white rounded-full px-8 py-4 shadow-xl text-xl mb-24"
          >
            Send project proposal
            <FontAwesomeIcon
              icon={faChevronRight}
              className="ml-2 h-4 group-hover:translate-x-2 transition-transform"
            />
          </button>
        </form>
      )

    case "dark":
      return (
        <form
          className="flex flex-col items-start w-full"
          name="Contact Form"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <div className="w-full flex flex-col items-center sm:flex-row">
            <div className="w-full mb-8 sm:pr-2">
              <label className="block mb-2" htmlFor="contact-name">
                Name
                <input
                  id="contact-name"
                  type="text"
                  className="p-3 text-lg rounded w-full placeholder:invisible bg-neutral-700 focus:bg-white focus:text-black focus:outline-none "
                  placeholder="Name"
                  required
                />
              </label>
            </div>
            <div className="w-full mb-8 sm:pl-2">
              <label className="block mb-2" htmlFor="contact-email">
                Email
                <input
                  id="contact-email"
                  type="email"
                  name="_replyto"
                  className="p-3 text-lg rounded w-full placeholder:invisible bg-neutral-700 focus:bg-white focus:text-black focus:outline-none"
                  placeholder="Email"
                  required
                />
              </label>
            </div>
          </div>

          <div className="w-full mb-8 relative">
            <label className="block mb-4" htmlFor="contact-message">
              Project Details
              <textarea
                id="contact-message"
                name="message"
                className="p-3 text-lg rounded w-full resize-y placeholder:invisible bg-neutral-700 focus:bg-white focus:text-black focus:outline-none"
                cols="30"
                rows="6"
                placeholder="message"
                required
              ></textarea>
            </label>
          </div>
          <button
            type="submit"
            className="px-6 py-3 text-lg rounded-md hover:text-neutral-500 text-black bg-white contact-form-btn"
          >
            Send project proposal
          </button>
        </form>
      )

    default:
      return (
        <form
          className="flex flex-col items-start w-full"
          name="Contact Form"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <div className="w-full flex flex-col items-center sm:flex-row">
            <div className="w-full mb-8 sm:pr-2">
              <label className="block mb-2" htmlFor="contact-name">
                Name
                <input
                  id="contact-name"
                  type="text"
                  className="p-3 text-lg rounded border border-neutral-300 w-full placeholder:invisible "
                  placeholder="Name"
                />
              </label>
            </div>
            <div className="w-full mb-8 sm:pl-2">
              <label className="block mb-2" htmlFor="contact-email">
                Email
                <input
                  id="contact-email"
                  type="email"
                  name="_replyto"
                  className="p-3 text-lg rounded border border-neutral-300 w-full placeholder:invisible"
                  placeholder="Email"
                />
              </label>
            </div>
          </div>
          <div className="w-full mb-8">
            <h4 className="mb-2">What services are you looking for?</h4>
            <div className="flex flex-wrap w-full justify-between">
              {questionaire.map(item => (
                <div className="pb-2">
                  <CheckboxCard name={item.name} value={item.value} />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full mb-8 relative">
            <label className="block mb-4" htmlFor="contact-message">
              Project Details
              <textarea
                id="contact-message"
                name="message"
                className="p-3 text-lg rounded border border-neutral-300 w-full resize-y placeholder:invisible"
                cols="30"
                rows="6"
                placeholder="message"
              ></textarea>
            </label>
          </div>

          <button
            type="submit"
            className="px-6 py-3 text-lg rounded-xl border border-blue-700 bg-blue-700 text-white contact-form-btn"
          >
            Send project proposal
          </button>
        </form>
      )
  }
}

export default ContactForm
