import { useState } from "react"

const CheckboxCard = ({ name, value }) => {
  const uncheckedStyles = "bg-white block rounded border border-gray-300 p-4"
  const checkedStyles =
    "bg-gray-900 block text-white rounded border border-gray-300 p-4"

  const [isChecked, setChecked] = useState(false)

  function handleIsChecked(e) {
    setChecked(!isChecked)
  }

  return (
    <label for={value} className={isChecked ? checkedStyles : uncheckedStyles}>
      <input
        type="checkbox"
        className="hidden"
        name="services"
        value={value}
        id={value}
        onClick={handleIsChecked}
      />
      {name}
    </label>
  )
}

export default CheckboxCard
