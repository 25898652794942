import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ContactForm from "../components/ContactForm"
import PageIntro2 from "../components/PageIntro2"

const ContactPage = ({ location }) => {
  return (
    <Layout location={location.pathname} navBarType="white">
      <Seo title="Contact Us" />
      <div className="w-full">
        <PageIntro2
          headline="Ready to get started?"
          text="Tells me about your project and I will get back to you within 24 hours."
          height={"60vh"}
        />
        <div className="flex flex-col lg:flex-row-reverse items-start pt-16 pb-8 container mx-auto">
          <div className="w-full lg:w-2/3">
            <ContactForm type="underlined" />
          </div>
          <div className="lg:pl-8 mb-4 w-full lg:w-1/3 flex flex-row flex-wrap lg:flex-col">
            <div className="w-full sm:w-1/2 lg:w-full">
              <h3 className="text-lg font-bold mb-4">Email:</h3>
              <p className="text-lg text-gray-700 mb-12">
                {"If it is easier, you can also send me an email at "}
                <a className="underline" href={"mailto:hello@cjhernandez.dev"}>
                  hello@cjhernandez.dev
                </a>
              </p>
            </div>
            {/* <h3 className="text-lg font-bold mb-4">Address:</h3>
                <p className="mb-8">1551 Hotwater onoff st.,
Kyiv city, UA 02000</p> */}
            <div className="w-full sm:w-1/2 lg:w-full">
              {/* <h3 className="text-lg font-bold mb-4">Hours of Operation:</h3>
                  <p className="mb-2">Monday - Friday</p>
                  <p className="mb-12">10:00 am - 7:30 pm</p> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
